import React, { useCallback } from "react";

import cx from "classnames";
import { Input, InputOnChangeData } from "semantic-ui-react";

import Modal from "~/components/fellow/Modal";
import { Button } from "~/ui";
import { __globalModalContext } from "~/ui/modals";

import "./prompts.less";

type ModalType = "confirm" | "warning" | "alert" | "prompt";

export type PromptResponse = string | boolean | null;

type ButtonProps = Omit<React.ComponentPropsWithoutRef<typeof Button>, "slim">;

interface PrompterProps {
	type: ModalType;
	content: React.ReactNode;
	title: React.ReactNode;
	resolve: (result: PromptResponse) => void;
	yesOnly?: boolean;
	yesProps?: ButtonProps;
	noProps?: ButtonProps;
	yesText?: string;
	noText?: string;
	required?: boolean;
	className?: string;
	initialInputValue?: string;
	simple?: boolean;
	inputPlaceholder?: string;
	noHeaderBorder?: boolean;
	noCloseButton?: boolean;
}

export function Prompter({
	type,
	content,
	title = "",
	resolve,
	yesOnly = false,
	yesProps = { variant: "primary" },
	noProps = {},
	yesText = "Yes",
	noText = "No",
	required = false,
	className = "",
	initialInputValue = "",
	simple = false,
	inputPlaceholder = "",
	noHeaderBorder,
	noCloseButton = false,
}: PrompterProps) {
	const [inputValue, setInputValue] = React.useState(initialInputValue);

	const handleClose = useCallback(() => {
		resolve(null);
	}, [resolve]);

	const handleSubmit = useCallback(
		(event?: React.FormEvent<HTMLFormElement>) => {
			if (event) event.preventDefault();

			let value = inputValue;
			if (!value && required) return;

			resolve(value);
		},
		[inputValue, required, resolve],
	);

	const handleYes = useCallback(() => {
		if (type === "prompt") return handleSubmit();

		resolve(true);
	}, [resolve, handleSubmit, type]);

	const handleNo = useCallback(() => {
		resolve(false);
	}, [resolve]);

	const handleInputChange = useCallback((_ev: React.SyntheticEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
		setInputValue(value);
	}, []);

	return (
		<Modal onClose={handleClose} className={cx("prompt", type, className, { simple: simple })}>
			{title && (
				<Modal.Header noBottomBorder onClose={noCloseButton ? undefined : handleClose}>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
			)}

			<Modal.Body textOnly className={!title ? "prompt__body--noheader" : undefined}>
				{content}
				{type === "prompt" && (
					<form onSubmit={handleSubmit}>
						<Input
							type="text"
							name="input"
							value={inputValue}
							required={required}
							onChange={handleInputChange}
							fluid
							placeholder={inputPlaceholder}
							autoFocus
						/>
					</form>
				)}
			</Modal.Body>

			<Modal.Footer data-test-id="prompt-modal-footer" noTopBorder={noHeaderBorder}>
				{!yesOnly && (
					<Button size="normal" variant="secondary" onClick={handleNo} {...noProps}>
						{noText}
					</Button>
				)}
				<Button size="normal" variant="primary" onClick={handleYes} data-test-id="yes-button" {...yesProps}>
					{yesText}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

interface PromptOptions {
	title: React.ReactNode;
	yesOnly?: boolean;
	yesProps?: ButtonProps;
	noProps?: ButtonProps;
	yesText?: string;
	noText?: string;
	required?: boolean;
	className?: string;
	initialInputValue?: string;
	simple?: boolean;
	inputPlaceholder?: string;
	noHeaderBorder?: boolean;
	noCloseButton?: boolean;
}

const createPrompt = (type: ModalType = "confirm", message: React.ReactNode, options: PromptOptions) => {
	const props = {
		content: message,
		...options,
	};

	if (!__globalModalContext) {
		return Promise.resolve(null);
	}

	return __globalModalContext.open<PromptResponse>(({ close }) => {
		return <Prompter type={type} {...props} resolve={close} />;
	});
};

export const confirm = (message: React.ReactNode, options: PromptOptions) => {
	return createPrompt("confirm", message, { yesText: "Ok", noText: "Cancel", ...options });
};

export const yesno = (message: React.ReactNode, options: PromptOptions) => {
	return createPrompt("confirm", message, { yesText: "Yes", noText: "No", ...options });
};

export const warning = (message: React.ReactNode, options: PromptOptions) => {
	return createPrompt("warning", message, {
		yesText: "Ok",
		noText: "Cancel",
		yesProps: { variant: "destructive" },
		...options,
	});
};

export const alert = (message: React.ReactNode, options: PromptOptions) => {
	return createPrompt("alert", message, { ...options, yesOnly: true });
};

export const prompt = (message: React.ReactNode, options: PromptOptions) => {
	return createPrompt("prompt", <p>{message}</p>, { yesText: "Ok", noText: "Cancel", required: true, ...options });
};
