import { OAuth2Client } from "@byteowls/capacitor-oauth2";
import { MsAuthPlugin } from "@recognizebv/capacitor-plugin-msauth";
import * as Sentry from "@sentry/browser";

import { OAuthParams } from "~/lib/auth/index";
import { ensureImported, parseJSONIfOk, resolveToPostMessage } from "~/lib/auth/nativeLib";
import { isIntuneActive } from "~/lib/intune/intune";
import { isiOSApp } from "~/lib/utils";
import { mobileVersion, Version, versionGte } from "~/lib/version";

// TODO: Needs to be added before MsAuthPlugin is used on Android
const ANDROID_KEY_HASH = "";

export const BASE_CONFIG = {
	clientId: window.MICROSOFT_CLIENT_ID,
	tenant: "common",
	keyHash: ANDROID_KEY_HASH,
};

export async function signOutOffice() {
	const currentMobileVersion: Version = await mobileVersion().catch(() => [1, 2, 0]);

	if (versionGte(currentMobileVersion, [1, 4, 0])) {
		try {
			await MsAuthPlugin.logout(BASE_CONFIG);
		} catch (e) {
			Sentry.captureException(e);
			console.error(e);
		}
		try {
			if (isIntuneActive()) {
				const { Intune } = await import("@fellow/intune");
				const email = window.INITIAL_STATE.user?.email;
				if (email) {
					await Intune.deRegisterAndUnenrollAccount({ email: email, withWipe: true });
				}
			}
		} catch (e) {
			Sentry.captureException(e);
			console.error(e);
		}
	}
}

export async function nativeOfficeLogin(params: OAuthParams = {}) {
	await ensureImported();
	await signOutOffice();

	const currentMobileVersion: Version = await mobileVersion().catch(() => [1, 2, 0]);

	let authOptions = {};

	// This should work for android as well once we get the ANDROID_HASH_KEY to be correct
	if (versionGte(currentMobileVersion, [1, 4, 0]) && isiOSApp) {
		await MsAuthPlugin.login({
			...BASE_CONFIG,
			scopes: ["Calendars.Read", "User.Read"],
		});
		// There's a bunch of documentation about this flow on https://dev.fellow.wiki
		const result = await MsAuthPlugin.login({
			...BASE_CONFIG,
			scopes: [`api://${window.ROOT_DOMAIN?.replace(/^\./, "")}/${window.MICROSOFT_CLIENT_ID}/.default`],
		});

		const body = {
			/* eslint-disable camelcase */
			access_token: result.accessToken,
			id_token: result.idToken,
			scope: result.scopes,
			params: params,
			/* eslint-enable camelcase */
		};

		return fetch("/office365/oauth/mobile_token_upload/", {
			method: "POST",
			credentials: "include",
			headers: {
				"content-type": "application/json",
				"x-client-session-id": window.CLIENT_SESSION_ID,
				"x-csrftoken": window.CSRF_TOKEN,
			},
			body: JSON.stringify(body),
		})
			.then(parseJSONIfOk)
			.then(resolveToPostMessage);
	} else {
		authOptions = {
			appId: window.MICROSOFT_CLIENT_ID,
			scope: params.scopes || "https://graph.microsoft.com/.default profile openid offline_access",
			authorizationBaseUrl: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
			accessTokenEndpoint: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
			authorizationCodeOnly: true,
			redirectUrl: window.MICROSOFT_MOBILE_URL,
			additionalParameters: {
				prompt: "select_account",
			},
			android: {
				responseType: "code", // if you configured a ios app in google dev console the value must be "code"
				customScheme: window.MICROSOFT_MOBILE_URL, // Bundle ID from google dev console
			},
			ios: {
				responseType: "code", // if you configured a ios app in google dev console the value must be "code"
				customScheme: window.MICROSOFT_MOBILE_URL, // Bundle ID from google dev console
			},
		};
		return OAuth2Client.authenticate(authOptions).then(
			(result: {
				/* eslint-disable camelcase */
				access_token_response: {
					access_token: string;
					refresh_token: string;
					expires_in: string;
					token_type: string;
					expires_at: string;
					id_token: string;
					scope: string;
				};
				/* eslint-enable camelcase */
			}) => {
				const accessTokenResponse = result["access_token_response"];

				const body = {
					/* eslint-disable camelcase */
					access_token: accessTokenResponse["access_token"],
					refresh_token: accessTokenResponse["refresh_token"],
					expires_in: accessTokenResponse["expires_in"],
					token_type: accessTokenResponse["token_type"],
					expires_on: accessTokenResponse["expires_at"],
					id_token: accessTokenResponse["id_token"],
					scope: accessTokenResponse["scope"],
					params: params,
					/* eslint-enable camelcase */
				};

				return fetch("/office365/oauth/mobile_token_upload/", {
					method: "POST",
					credentials: "include",
					headers: {
						"content-type": "application/json",
						"x-client-session-id": window.CLIENT_SESSION_ID,
						"x-csrftoken": window.CSRF_TOKEN,
					},
					body: JSON.stringify(body),
				})
					.then(parseJSONIfOk)
					.then(resolveToPostMessage);
			},
		);
	}
}
