import * as device from "~/lib/device";

// eslint-disable-next-line max-len
export const appVersionRegex =
	/^(\d+)(?:\.(\d+))?(?:\.(\d+))?((?:a|b|rc)\d+)?(?:\.post(\d+))?(?:[+-]([\da-z]+)\.d(\d{8}))?/;

/**
 * A helper class for access parts of our versioning scheme. Here are the member variables it'll make:
 *
 *      primaryString
 *      |   pre
 *    ----- |
 *         ---
 *    1.2.3rc4.post5+a1b2c3d4.d20180101
 *    - - -        - ------------------
 *    | | |        |         |
 *    | | patch    post      local
 *    | minor
 *    major
 *
 * @param  string  versionString  A version string in our usual format.
 */
export default class FellowVersion {
	string = "";
	major = "";
	minor = "";
	patch = "";
	pre = "";
	post = "";
	local = "";
	primaryString = "";

	constructor(version = "") {
		const result = appVersionRegex.exec(version);
		if (result !== null) {
			[this.string, this.major, this.minor, this.patch, this.pre, this.post, this.local] = result;
			this.primaryString = [this.major, this.minor, this.patch].join(".");
		}
	}
}

export type Version = number[];

const mobileVersionRegex = /^(\d+)\.(\d+)(?:\.(\d+))?/;

export function versionParse(versionString: string): Version {
	const match = mobileVersionRegex.exec(versionString);
	if (match === null) {
		throw new Error(`Invalid version ${versionString}`);
	}

	// first element is the whole match which we don't care about
	const [, major, minor, patch] = match;
	// filter out undefined in case of version like "1.1"
	return [major, minor, patch].filter(n => n).map(n => Number.parseInt(n, 10));
}

export async function mobileVersion(): Promise<Version> {
	const appVersion = await device.getNativeAppVersion();

	return versionParse(appVersion);
}

const desktopVersionRegex = /\bFellow\/(\d+\.\d+(?:\.\d+)?)/;

export function desktopVersion(): Version | null {
	const desktopVersionMatch = navigator.userAgent.match(desktopVersionRegex);

	if (desktopVersionMatch === null) {
		return null;
	}

	// first element is the whole match which we don't care about
	const [, unparsedVersion] = desktopVersionMatch;

	return versionParse(unparsedVersion);
}

export function versionCompare(a: Version, b: Version): -1 | 0 | 1 {
	const maxLen = Math.max(a.length, b.length);

	for (let i = 0; i < maxLen; i += 1) {
		// if one version is missing a segment, assume it's greater to be safe
		if (a[i] === undefined) return 1;
		if (b[i] === undefined) return -1;
		if (a[i] > b[i]) return 1;
		if (a[i] < b[i]) return -1;
	}

	return 0;
}

export function versionGt(a: Version, b: Version): boolean {
	return versionCompare(a, b) === 1;
}

export function versionLt(a: Version, b: Version): boolean {
	return versionCompare(a, b) === -1;
}

export function versionEq(a: Version, b: Version): boolean {
	return versionCompare(a, b) === 0;
}

export function versionGte(a: Version, b: Version): boolean {
	return versionCompare(a, b) >= 0;
}

export function versionLte(a: Version, b: Version): boolean {
	return versionCompare(a, b) <= 0;
}
