import React, { useMemo } from "react";

import { faCheck, faMinus } from "@awesome.me/kit-4c83d50d0d/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Indicator, CheckboxProps as RadixProps, Root } from "@radix-ui/react-checkbox";
import cx from "classnames";

import styles from "./Checkbox.module.css";

declare module "csstype" {
	interface Properties {
		/** Background colour for Fellow's checkboxes */
		"--fellowCheckboxColor"?: string;
	}
}

export type CheckboxProps = Omit<RadixProps, "asChild" | "color"> & {
	/**
	 * Any valid CSS colour, used for the background when checked
	 * @default var(--veryVividGreen)
	 */
	color?: string;
	/**
	 * Sets the size of the checkbox. Can be any valid CSS length
	 * value, e.g. "1rem", "24px", "10vmin".
	 *
	 * @default The current font size
	 */
	size?: string;
};

export default function Checkbox({ className, size, checked, color, style, ...props }: CheckboxProps) {
	return (
		<Root
			className={cx(styles.checkbox, className)}
			checked={checked}
			style={useMemo(
				() => ({
					"--fellowCheckboxColor": color,
					fontSize: size,
					...style,
				}),
				[color, size, style],
			)}
			{...props}
		>
			<Indicator className={styles.indicator}>
				{checked === "indeterminate" ? (
					<FontAwesomeIcon icon={faMinus} className={styles.icon} />
				) : (
					<FontAwesomeIcon icon={faCheck} className={styles.icon} />
				)}
			</Indicator>
		</Root>
	);
}
