/**
 * @generated SignedSource<<637a886f0aaafc1ccfa78818c0e920b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleBlockFieldField = "DONE" | "WONT_DO";
export type ToggleBlocksFieldInput = {
  ids?: ReadonlyArray<BlockID> | null;
  field: ToggleBlockFieldField;
  value: boolean;
  fromTrayApp?: boolean | null;
  clientMutationId?: string | null;
};
export type BlockID = {
  id?: string | null;
  noteId?: string | null;
  blockKey?: string | null;
};
export type mutationsToggleBlocksFieldMutation$variables = {
  input: ToggleBlocksFieldInput;
};
export type mutationsToggleBlocksFieldMutation$data = {
  readonly toggleBlocksField: {
    readonly noteBlockEdges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly updatedAt: string | null;
        readonly statusChangedAt: string | null;
      } | null;
    }>;
  } | null;
};
export type mutationsToggleBlocksFieldMutation = {
  variables: mutationsToggleBlocksFieldMutation$variables;
  response: mutationsToggleBlocksFieldMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleBlocksFieldPayload",
    "kind": "LinkedField",
    "name": "toggleBlocksField",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteBlockEdge",
        "kind": "LinkedField",
        "name": "noteBlockEdges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NoteBlock",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "statusChangedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutationsToggleBlocksFieldMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutationsToggleBlocksFieldMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1851618e20bb7ef58abca5ae2765d787",
    "id": null,
    "metadata": {},
    "name": "mutationsToggleBlocksFieldMutation",
    "operationKind": "mutation",
    "text": "mutation mutationsToggleBlocksFieldMutation(\n  $input: ToggleBlocksFieldInput!\n) {\n  toggleBlocksField(input: $input) {\n    noteBlockEdges {\n      node {\n        id\n        updatedAt\n        statusChangedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9cda59ec211b2c3123738d6e831bb57";

export default node;
