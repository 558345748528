import { createBrowserHistory, TransitionPromptHook } from "history";

const history = createBrowserHistory<{ blockable?: boolean }>();
const historyBlockers: TransitionPromptHook[] = [];
history.block((location, action) => {
	if (location.state && location.state.blockable === false) return undefined;

	for (const blocker of historyBlockers) {
		const res = blocker(location, action);
		if (res) return res;
	}
	return undefined;
});
export const addBlocker = (blocker: TransitionPromptHook) => {
	historyBlockers.push(blocker);
	return () => {
		const index = historyBlockers.indexOf(blocker);
		if (index > -1) {
			historyBlockers.splice(index, 1);
		}
	};
};
history.listen(location => {
	// A comment in the django template base.html points to this code, if moving this code please update that.
	const storeLink = document.getElementById("ios-app-store-link");
	if (storeLink) {
		let content = storeLink.getAttribute("content");
		if (content) {
			content = content.replace(/app-argument=.*?(,|$)/, `app-argument=${location.pathname}$1`);
			storeLink.setAttribute("content", content);
		}
	}
});
export default history;
