import { GrowthBook } from "@growthbook/growthbook-react";
import { noop } from "lodash";

const HEADERS = {
	"x-client-session-id": window.CLIENT_SESSION_ID,
	"x-csrftoken": window.CSRF_TOKEN,
	"x-requested-with": "XMLHttpRequest",
};

const growthbook = new GrowthBook({
	...window.INITIAL_STATE?.growthbook,
	enableDevMode: window.ENVIRONMENT !== "prod",
	trackingCallback: (experiment, result) => {
		const data = new FormData();
		data.append("experiment_id", experiment.key);
		data.append("variation_id", String(result.variationId));
		data.append("feature_id", String(result.featureId));

		fetch("/ab/growthbook/track/", {
			method: "POST",
			body: data,
			headers: HEADERS,
		}).catch(noop);
	},
});

export function trackConversion(experimentId: string, value = 0) {
	const data = new FormData();
	data.append("experiment_id", experimentId);
	data.append("value", String(value));

	fetch("/ab/growthbook/convert/", {
		method: "POST",
		body: data,
		headers: HEADERS,
	}).catch(noop);
}

export default growthbook;
