import React from "react";
import Relay, {
	ConnectionConfig,
	ContainerProps,
	FetchPolicy,
	GraphQLTaggedNode,
	QueryRenderer,
	UseMutationConfig,
} from "react-relay";

import hoistNonReactStatics from "hoist-non-react-statics";
import relay, {
	CacheConfig,
	ConcreteRequest,
	createOperationDescriptor,
	Disposable,
	Environment,
	MutationConfig,
	MutationParameters,
	OperationType,
	Variables,
} from "relay-runtime";

import ErrorBoundary from "~/components/common/ErrorBoundary";
import { urlsafeB64Decode } from "~/lib/base64";
import { Queue } from "~/lib/collections";
import { createMutableContext } from "~/lib/context";
import { environment } from "~/models";

export const globalFetchKeyContext = createMutableContext<number>("GlobalFetchKeyContext", 0);

interface DumbFragmentComponent<P> extends React.ComponentClass<P> {
	Fragments: {
		[key: string]: GraphQLTaggedNode;
	};
}

export type SmartPaginationConfig<P> = Omit<ConnectionConfig<P>, "query">;
interface DumbPaginationComponent<P> extends DumbFragmentComponent<P & { relay: Relay.RelayPaginationProp }> {
	PaginationQuery: GraphQLTaggedNode;
	PaginationConfig: SmartPaginationConfig<P>;
}

interface DumbRefetchComponent<P> extends DumbFragmentComponent<P & { relay: Relay.RelayRefetchProp }> {
	Refetch: GraphQLTaggedNode;
}

type DumbComponent<P> = DumbFragmentComponent<P> | DumbPaginationComponent<P> | DumbRefetchComponent<P>;

interface RunQueryProps<T extends OperationType> {
	query: GraphQLTaggedNode;
	fetchPolicy?: FetchPolicy;
	fetchKey?: string | number;
	variables: T["variables"];
	onResult: (result: T["response"]) => void;
}

/**
 * RunQuery allows a component to run a gr*phql query and be aware of when that
 * query is loading and if it fails.
 *
 * This functionality was previously provided by useQuery from relay-hooks, but
 * since we've switched to react-relay's hooks, we only have access to
 * useLazyLoadQuery, which suspends the querying component while loading.
 */
export function RunQuery<T extends OperationType>({
	onLoading,
	onError,
	...props
}: RunQueryProps<T> & { onLoading?: () => void; onError?: (error: Error) => void }) {
	const errorFallbackComponent = React.useMemo(() => {
		return function ErrorFallback({ error }: { error: Error }) {
			React.useEffect(() => onError?.(error), [error]);
			return null;
		};
	}, [onError]);

	const content = (
		<React.Suspense fallback={<RunQueryFallback onLoading={onLoading} />}>
			<RunQueryInner<T> {...props} />
		</React.Suspense>
	);

	if (onError) {
		return <ErrorBoundary fallbackComponent={errorFallbackComponent}>{content}</ErrorBoundary>;
	}
	return content;
}

function RunQueryFallback({ onLoading }: { onLoading?: () => void }) {
	React.useEffect(() => onLoading?.(), [onLoading]);

	return null;
}

function RunQueryInner<T extends OperationType>({ query, variables, onResult, ...opts }: RunQueryProps<T>) {
	const data = useLazyLoadQuery<T>(query, variables, opts);

	React.useEffect(() => {
		onResult(data);
	}, [onResult, data]);

	return null;
}

/**
 * A wrapper around react-relay's useLazyLoadQuery that applies our default
 * options.
 */
export function useLazyLoadQuery<TQuery extends OperationType>(
	query: GraphQLTaggedNode,
	variables: TQuery["variables"],
	options: Parameters<typeof Relay.useLazyLoadQuery>[2],
): TQuery["response"] {
	const globalFetchKey = globalFetchKeyContext.useValue();

	return Relay.useLazyLoadQuery<TQuery>(query, variables, {
		// This option is to tell relay to wait until all data is available before
		// allowing rendering to continue. That is, if the fetchPolicy involves the
		// store, it won't try to render if only some of the data is currently
		// available in the store.
		UNSTABLE_renderPolicy: "full", // eslint-disable-line camelcase
		...options,
		fetchKey: `${globalFetchKey}-${options?.fetchKey ?? ""}`,
	});
}

/**
 * HOC: Easily turn a Component Class into a [Fragment Container][fc], [Refetch Container][rc],
 * or [Pagination Container][pc], depending on what fragments are statically defined on the class.
 *
 * @typeparam P - The properties accepted by the dumb component
 *
 * @todo HOCs, including this one, break `defaultProps` as of React v16.8.6/TypeScript v3.4.2. See
 * the [discussion] in the DefinitelyTyped repo on Github for more info.
 *
 * [fc]: https://facebook.github.io/relay/docs/en/fragment-container
 * [rc]: https://facebook.github.io/relay/docs/en/refetch-container
 * [pc]: https://facebook.github.io/relay/docs/en/pagination-container
 * [discussion]: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30791
 */
export function smart<P extends {}>(
	dumb: DumbComponent<P>,
): React.ComponentClass<ContainerProps<P> & { componentRef?: (ref: unknown) => void }> {
	// NOTE: This is a mess of types. Yes, the returns are redundant lines, but they keep types in check

	if ("Refetch" in dumb) {
		const relayContainer = Relay.createRefetchContainer(dumb, dumb.Fragments, dumb.Refetch);
		hoistNonReactStatics(relayContainer, dumb);
		return relayContainer as React.ComponentClass<ContainerProps<P> & { componentRef?: (ref: unknown) => void }>;
	} else if ("PaginationQuery" in dumb && "PaginationConfig" in dumb) {
		const relayContainer = Relay.createPaginationContainer(dumb, dumb.Fragments, {
			...dumb.PaginationConfig,
			query: dumb.PaginationQuery,
			// NOTE: Removing `as unknown as T` and fixing the underlying issue could be a fun typescript knowledge challenge,
			// I suppose. I believe the first thing you would need to do is to stop extending P with {}. This stack overflow
			// post explains why it's a bad idea. -Wal
			// https://stackoverflow.com/questions/56505560/how-to-fix-ts2322-could-be-instantiated-with-a-different-subtype-of-constraint
		} as unknown as ConnectionConfig);
		hoistNonReactStatics(relayContainer, dumb);
		return relayContainer as React.ComponentClass<ContainerProps<P> & { componentRef?: (ref: unknown) => void }>;
	} else {
		const relayContainer = Relay.createFragmentContainer(dumb, dumb.Fragments);
		hoistNonReactStatics(relayContainer, dumb);
		return relayContainer as React.ComponentClass<ContainerProps<P> & { componentRef?: (ref: unknown) => void }>;
	}
}

interface QueryProps<T extends OperationType> {
	query: GraphQLTaggedNode | null;
	fallback: React.ReactNode;
	variables: T["variables"];
	children: (props: T["response"]) => React.ReactNode;
	cacheConfig?: CacheConfig | null;
	fetchPolicy?: FetchPolicy;
}

export class Query<T extends OperationType> extends React.Component<QueryProps<T>> {
	static defaultProps = {
		fallback: null,
		variables: {},
	};

	renderContents: QueryRenderer<T>["props"]["render"] = ({ props, error }) => {
		if (error) {
			throw error;
		} else if (props) {
			return this.props.children(props);
		} else if (this.props.fallback) {
			return this.props.fallback;
		} else {
			return null;
		}
	};

	render(): React.ReactNode {
		return (
			<QueryRenderer<T>
				environment={environment}
				query={this.props.query}
				variables={this.props.variables}
				render={this.renderContents}
				fetchPolicy={this.props.fetchPolicy}
				cacheConfig={this.props.cacheConfig}
			/>
		);
	}
}

function wrapCommit<
	TOperation extends MutationParameters,
	TConfig extends MutationConfig<TOperation> | UseMutationConfig<TOperation>,
>(commit: (config: TConfig) => Disposable): (config: TConfig) => Promise<TOperation["response"]> {
	return config => {
		return new Promise((resolve, reject) => {
			const { onCompleted, onError } = config;
			commit({
				...config,
				onCompleted(payload: TOperation["response"], errors: relay.PayloadError[] | null | undefined) {
					if (errors) reject(errors);
					if (onCompleted) onCompleted(payload, errors ?? null);
					resolve(payload);
				},
				onError(e: Error) {
					if (onError) onError(e);
					reject(e);
				},
			});
		});
	};
}

/**
 * Commit a GraphQL mutation.
 *
 * This function wraps relay's `commitMutation`
 * function in a promise, which can makes it easier to do stuff after the
 * mutation is complete.
 */
export function commitMutationPromise<TOperation extends MutationParameters = MutationParameters>(
	environment: Environment,
	mutationConfig: MutationConfig<TOperation>,
): Promise<TOperation["response"]> {
	return wrapCommit<TOperation, MutationConfig<TOperation>>(config => relay.commitMutation(environment, config))(
		mutationConfig,
	);
}

/**
 * Hook used to execute a mutation in a React component.
 *
 * This is a wrapper around useMutation from react-relay where the `commit`
 * function returns a Promise instead of a Disposable.
 */
export function useMutationPromise<TOperation extends MutationParameters = MutationParameters>(
	mutation: Relay.GraphQLTaggedNode,
): [commit: (config: UseMutationConfig<TOperation>) => Promise<TOperation["response"]>, isInFlight: boolean] {
	const [commit, isInFlight] = Relay.useMutation<TOperation>(mutation);

	const commitPromise = React.useMemo(() => wrapCommit<TOperation, UseMutationConfig<TOperation>>(commit), [commit]);

	return [commitPromise, isInFlight];
}

/**
 * retainForever can be used to have relay-runtime never garbage-collect the
 * data received from the given query and variables.
 *
 * This is useful when the variables are known to never change. If the
 * variables do change and this function is called for the different variables,
 * it will leak memory.
 */
export function retainForever(request: ConcreteRequest, variables: Variables = {}): void {
	environment.retain(createOperationDescriptor(request, variables));
}

/**
 * LRURetainer can be used to retain the last `capacity` requests' data.
 *
 * As an example, we are using this to retain the last several notestreams the
 * user visits, so that returning to any of those is very fast.
 *
 * Once the LRURetainer has reached capacity, retaining further queries will
 * dispose the oldest one still retained.
 */
export class LRURetainer implements Disposable {
	private disposables: Queue<Disposable>;
	public readonly capacity: number;

	constructor(capacity: number) {
		this.disposables = new Queue();
		this.capacity = capacity;
	}

	retain(query: ConcreteRequest, variables: Variables) {
		this.disposables.put(environment.retain(createOperationDescriptor(query, variables)));
		while (this.disposables.size > this.capacity) {
			this.disposables.get()?.dispose();
		}
	}

	dispose() {
		while (!this.disposables.isEmpty) {
			this.disposables.get()?.dispose();
		}
	}
}

/**
 * SingletonRetainer is similar to LRUContainer with capacity of 1. It will
 * retain up to 1 query at any given time, disposing whatever was already
 * retained before.
 *
 * This is useful for queries where the variables may change, but we are only
 * interested in retaining the data for the last set of variables. It's also
 * useful for making sure a query isn't retained more than once (i.e. creating
 * a memory leak), since retaining the same query and variables more than once
 * with SingletonRetainer is idempotent.
 *
 * We are using this in the Analytics section to make accidentally introducing
 * a memory leak more difficult.
 */
export class SingletonRetainer implements Disposable {
	private disposable?: Disposable;

	retain(query: ConcreteRequest, variables: Variables) {
		// Retain the new one before disposing the old one, in case they're the
		// same one (to avoid the refcount dropping to 0 and potentially clearing
		// the data in between).
		const newDisposable = environment.retain(createOperationDescriptor(query, variables));
		this.disposable?.dispose();
		this.disposable = newDisposable;
	}

	dispose() {
		this.disposable?.dispose();
	}
}

/**
 * Extract the type name and ID from a Relay global ID.
 *
 * In general this should be avoided on the client side, since these IDs are
 * meant to be opaque, but sometimes you gotta do what you gotta do.
 */
export function decodeGlobalId(globalId: string): [type: string, id: string] {
	const decoded = urlsafeB64Decode(globalId);
	const [type, ...idParts] = decoded.split(":");
	const id = idParts?.join(":");
	if (!id) throw new Error(`Invalid global ID "${globalId}"`);
	return [type, id];
}

/**
 * A type guard to filter out "%other" types from Relay's discriminated unions.
 */
export type NotOther<T extends {} | { __typename: string }> = Exclude<T, { __typename: "%other" }>;

/**
 * A type guard to filter out "%other" types from Relay's discriminated unions.
 */
export function isNotOther<T extends {} | { __typename: "%other" }>(item: T): item is NotOther<T> {
	if ("__typename" in item && item.__typename === "%other") return false;
	return true;
}
