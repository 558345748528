interface Node<T> {
	next: Node<T> | null;
	value: T;
}

/**
 * A basic queue, implementing constant-time puts and gets. The size is
 * maintained as state on the queue, so accessing it is constant time as well.
 */
export class Queue<T> {
	private start: Node<T> | null = null;
	private end: Node<T> | null = null;
	private _size = 0;

	get(): T | undefined {
		if (this.start === null) {
			return undefined;
		}

		const { value, next } = this.start;
		if (!next) {
			this.end = null;
		}
		this.start = next;
		this._size -= 1;
		return value;
	}

	put(value: T) {
		const node = { value, next: null };
		if (this.end) {
			this.end.next = node;
		} else {
			this.start = node;
		}
		this.end = node;
		this._size += 1;
	}

	get head(): T {
		if (!this.start) {
			throw new RangeError("Getting head of empty queue");
		}
		return this.start.value;
	}

	get size(): number {
		return this._size;
	}

	get isEmpty(): boolean {
		return this.size === 0;
	}

	*[Symbol.iterator]() {
		for (let node = this.start; node; node = node.next) {
			yield node.value;
		}
	}

	toString(): string {
		const buf = [];
		for (const value of this) {
			buf.push(String(value));
		}
		return `Queue{${buf.join(", ")}}`;
	}
}
