import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

import { OAuthParams } from "~/lib/auth/index";
import { ensureImported, MobileTokenUploadResponse, parseJSONIfOk, resolveToPostMessage } from "~/lib/auth/nativeLib";
import { isNative } from "~/lib/utils";

export async function signOutGoogle() {
	if (isNative) {
		await ensureImported();

		await GoogleAuth.signOut();
	}
}

function getAndUploadToken(params: OAuthParams, retries: number): Promise<MobileTokenUploadResponse> {
	return GoogleAuth.signIn().then(async result => {
		const response = result.authentication;
		const serverAuthCode = result.serverAuthCode;

		if (!serverAuthCode) {
			// In some cases if the user has already logged in they won't get a serverAuthCode, if this is the case
			// Trigger a sign out and Retry logging in, that should ensure we receive a serverAuthCode.
			if (retries === 0) {
				throw new Error("Failed to get serverAuthCode after 3 tries");
			}
			await signOutGoogle();
			return getAndUploadToken(params, retries - 1);
		}

		return fetch("/google/oauth/mobile_token_upload/", {
			method: "POST",
			credentials: "include",
			headers: {
				"content-type": "application/json",
				"x-client-session-id": window.CLIENT_SESSION_ID,
				"x-csrftoken": window.CSRF_TOKEN,
			},
			body: JSON.stringify({
				/* eslint-disable camelcase */
				server_auth_code: serverAuthCode,
				id_token: response["idToken"],
				params: params,
				/* eslint-enable camelcase */
			}),
		}).then(parseJSONIfOk);
	});
}

export async function nativeGoogleLogin(params: OAuthParams = {}, retries: number) {
	let retriesCounter = retries;
	await ensureImported();
	return getAndUploadToken(params, retriesCounter)
		.then(json => {
			if (!json.success && json.message === "Incorrect code for Google servers") {
				if (retriesCounter > 0) {
					return signOutGoogle().then(() => {
						retriesCounter = retriesCounter - 1;
						return getAndUploadToken(params, retriesCounter);
					});
				} else {
					throw new Error("Login failed, please wait and try again or contact support if this issue persists.");
				}
			}
			return json;
		})
		.then(resolveToPostMessage);
}
