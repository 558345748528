export function openWindowCentered(url: string, title: string, w: number, h: number): Window | null {
	const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
	const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

	const width = window.innerWidth
		? window.innerWidth
		: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: screen.width;
	const height = window.innerHeight
		? window.innerHeight
		: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: screen.height;

	const left = (width - w) / 2 + dualScreenLeft;
	const top = (height - h) / 2 + dualScreenTop;

	const result = window.open(url, title, `width=${w},height=${h},top=${top},left=${left}`);
	if (result) result.focus();

	return result;
}
