import { Browser } from "@capacitor/browser";
import { AuthBrowser } from "@fellow/auth-browser";
import * as Sentry from "@sentry/browser";
import { noop } from "lodash";

import { AuthPostMessage, getOAuthPromptParams, getOAuthPromptUri, OAuthParams } from "~/lib/auth/index";

export async function promptNativeOAuthIOS(path: string, oAuthParams: OAuthParams): Promise<AuthPostMessage> {
	const uri = getOAuthPromptUri(path);

	const clonedOAuthParams: OAuthParams = { ...oAuthParams, redirectTicketToURLEncodedSchemeOnLastOauthStep: true };

	const params = getOAuthPromptParams(clonedOAuthParams);

	const result = await AuthBrowser.start({
		url: `${uri}?${params.toString()}`,
		scheme: "fellow",
	});

	if (result.success) {
		window.location.href = decodeURIComponent(result.url);
	} else {
		Sentry.captureMessage("Failed to authenticate", {
			extra: {
				error: result.error,
			},
		});
		throw { code: null, message: "Login Failed" };
	}

	// The oauth flow should be completed by oauth_redirect_popup navigating the app
	// So this promise will never resolve
	return new Promise<AuthPostMessage>(noop);
}

export async function promptNativeOAuthAndroid(path: string, oAuthParams: OAuthParams): Promise<AuthPostMessage> {
	const uri = getOAuthPromptUri(path);

	const params = getOAuthPromptParams(oAuthParams);

	await Browser.open({
		url: `${uri}?${params.toString()}`,
	});

	// The oauth flow should be completed by oauth_redirect_popup navigating the app
	// So this promise will never resolve unless the login fails.
	return new Promise<AuthPostMessage>((resolve, reject) => {
		Browser.addListener("browserFinished", () => {
			reject({ code: null, message: "Login Failed" });
		});
	});
}
