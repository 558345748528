import React from "react";
import { Link, LinkProps } from "react-router-dom";

import cx from "classnames";
import { LocationState } from "history";

import { ProductFamilyKey } from "~/components/billing/utils";

import styles from "./Button.module.css";

type FellowButtonProps = {
	size?:
		| "normal"
		| "slim"
		/** @deprecated */
		| "wide";
	variant?:
		| "primary"
		| "primary-ai"
		| "secondary"
		| "tertiary"
		| "destructive"
		| ProductFamilyKey
		| "billing"
		| "placeholder";
	disabled?: boolean;
	round?: boolean;
};

export type ButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, "ref" | "size"> &
	React.ButtonHTMLAttributes<HTMLButtonElement> &
	FellowButtonProps;

/**
 * This is our button. There are many like it, but this one is ours.
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, size = "normal", variant = "secondary", round = false, type = "button", children, ...rest }, ref) => {
		const classNames = cx(styles.button, styles[`size-${size}`], styles[`variant-${variant}`], className, {
			[styles.disabled]: rest.disabled,
			[styles.round]: round,
		});

		return (
			<button ref={ref} {...rest} type={type} className={classNames}>
				{children}
			</button>
		);
	},
);

Button.displayName = "Button";

export type LinkButtonProps<LS extends LocationState> = LinkProps<LS> & FellowButtonProps;

/**
 * A button that can trigger navigation.
 */
export const LinkButton = <T extends LocationState>({
	className,
	size = "normal",
	variant = "secondary",
	round = false,
	children,
	...rest
}: LinkButtonProps<T>) => {
	const classNames = cx(styles.button, styles[`size-${size}`], styles[`variant-${variant}`], className, {
		[styles.disabled]: rest.disabled,
		[styles.round]: round,
	});
	return (
		<Link<T> {...rest} className={classNames}>
			{children}
		</Link>
	);
};

LinkButton.displayName = "LinkButton";

export type AnchorButtonProps = Omit<React.HTMLProps<HTMLAnchorElement>, "ref" | "size"> &
	React.AnchorHTMLAttributes<HTMLAnchorElement> &
	FellowButtonProps;

/**
 * An anchor that looks like a button.
 */
export const AnchorButton = ({
	className,
	size = "normal",
	variant = "secondary",
	round = false,
	children,
	...rest
}: AnchorButtonProps) => {
	const classNames = cx(styles.button, styles[`size-${size}`], styles[`variant-${variant}`], className, {
		[styles.disabled]: rest.disabled,
		[styles.round]: round,
	});
	return (
		<a {...rest} className={classNames}>
			{children}
		</a>
	);
};

AnchorButton.displayName = "AnchorButton";

export default Button;
