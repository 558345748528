/**
 * @generated SignedSource<<d05a4f3188fc2f56e1e4d68e55f91bfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type utils_publicGetClipLinkQuery$variables = {
  clipId: string;
};
export type utils_publicGetClipLinkQuery$data = {
  readonly node: {
    readonly __typename: "MeetingRecordingClip";
    readonly id: string;
    readonly url: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type utils_publicGetClipLinkQuery = {
  variables: utils_publicGetClipLinkQuery$variables;
  response: utils_publicGetClipLinkQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clipId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "clipId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "forCopy",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "url",
  "storageKey": "url(forCopy:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "utils_publicGetClipLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "MeetingRecordingClip",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "utils_publicGetClipLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "MeetingRecordingClip",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c991f4d9917fc7242b750e922e212dc",
    "id": null,
    "metadata": {},
    "name": "utils_publicGetClipLinkQuery",
    "operationKind": "query",
    "text": "query utils_publicGetClipLinkQuery(\n  $clipId: ID!\n) {\n  node(id: $clipId) {\n    __typename\n    ... on MeetingRecordingClip {\n      id\n      url(forCopy: true)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0a861e0bc397250e69b803309ea822d1";

export default node;
