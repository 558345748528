import React from "react";

import cx from "classnames";
import fscreen from "fscreen";

import styles from "./css/Fullscreen.module.css";

export function useCurrentFullscreenElement(): HTMLElement | null {
	const forceUpdate = React.useReducer(() => ({}), {})[1];
	React.useEffect(() => {
		fscreen.addEventListener("fullscreenchange", forceUpdate);
		return () => {
			fscreen.removeEventListener("fullscreenchange", forceUpdate);
		};
	});
	if (fscreen.fullscreenElement instanceof HTMLElement) return fscreen.fullscreenElement;
	else return null;
}

interface FullscreenProps {
	enabled: boolean;
	onChange: (fullscreen: boolean) => void;
	className?: string;
}

export default class Fullscreen extends React.Component<FullscreenProps> {
	private fsRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		fscreen.addEventListener("fullscreenchange", this.keyboardFullscreenChange);
	}

	componentWillUnmount() {
		fscreen.removeEventListener("fullscreenchange", this.keyboardFullscreenChange);
	}

	componentDidUpdate(prevProps: FullscreenProps) {
		if (prevProps.enabled != this.props.enabled) {
			this.handleProps(this.props);
		}
	}

	handleProps(props: FullscreenProps) {
		const enabled = fscreen.fullscreenElement === this.fsRef.current;
		if (enabled && !props.enabled) {
			this.leaveFullScreen();
		} else if (!enabled && props.enabled) {
			this.enterFullScreen();
		}
	}

	keyboardFullscreenChange = () => {
		this.props.onChange(fscreen.fullscreenElement === this.fsRef.current);
	};

	enterFullScreen() {
		if (fscreen.fullscreenEnabled && this.fsRef.current) {
			fscreen.requestFullscreen(this.fsRef.current);
		}
	}

	leaveFullScreen() {
		if (fscreen.fullscreenEnabled) fscreen.exitFullscreen();
	}

	render(): React.ReactNode {
		return (
			<div
				className={cx(this.props.className, {
					[styles.fullscreen]: this.props.enabled,
					fullscreen: this.props.enabled,
				})}
				ref={this.fsRef}
			>
				{this.props.children}
			</div>
		);
	}
}
