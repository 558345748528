import React from "react";

import * as Sentry from "@sentry/browser";

type ErrorBoundaryState =
	| { hasError: false; error: null }
	| {
			hasError: true;
			error: Error;
	  };

type ErrorBoundaryProps = { onError?: (error: Error) => void } & (
	| {
			fallback: React.ReactNode;
	  }
	| {
			fallbackComponent: React.ComponentType<{ error: Error; retry: () => void }>;
	  }
);

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	state: ErrorBoundaryState = { hasError: false, error: null };

	static getDerivedStateFromError(error: Error): ErrorBoundaryState {
		return { hasError: true, error };
	}

	componentDidCatch(error: Error) {
		console.error("Caught error:", error);
		Sentry.captureException(error);
		this.props.onError?.(error);
	}

	retry = () => {
		this.setState({ hasError: false, error: null });
	};

	render() {
		if (this.state.hasError) {
			if ("fallbackComponent" in this.props) {
				return <this.props.fallbackComponent error={this.state.error} retry={this.retry} />;
			}
			return this.props.fallback;
		}

		return this.props.children;
	}
}
