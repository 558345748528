export class DebugLogger {
	constructor(public namespace: string) {
		this.log = this.log.bind(this);
	}

	log(...args: unknown[]): void {
		console.debug(this.namespace, ...args);
	}

	warn(...args: unknown[]): void {
		console.warn(this.namespace, ...args);
	}
}

/**
 * If `cond` is falsy and this is a development environment, the message is
 * logged as an error in the console.
 */
export function debugAssert(cond: unknown, message: string, ...messageArgs: unknown[]): void {
	if (window.ENVIRONMENT !== "prod") {
		console.assert(cond, message, ...messageArgs);
	}
}
