/**
 * @generated SignedSource<<26dda4d6dc176446fee02d1239dfb7c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type utils_publicGetRecordingLinkQuery$variables = {
  accessKey: string;
  recordingId?: string | null;
};
export type utils_publicGetRecordingLinkQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly meetingRecordingLink?: {
      readonly url: string;
    };
  } | null;
};
export type utils_publicGetRecordingLinkQuery = {
  variables: utils_publicGetRecordingLinkQuery$variables;
  response: utils_publicGetRecordingLinkQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessKey"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "recordingId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "accessKey"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "forCopy",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "recordingId",
      "variableName": "recordingId"
    }
  ],
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "utils_publicGetRecordingLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MeetingRecordingLink",
                "kind": "LinkedField",
                "name": "meetingRecordingLink",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Note",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "utils_publicGetRecordingLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeetingRecordingLink",
                "kind": "LinkedField",
                "name": "meetingRecordingLink",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Note",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a178ac41333f33daa48fc6e4ea1bbff4",
    "id": null,
    "metadata": {},
    "name": "utils_publicGetRecordingLinkQuery",
    "operationKind": "query",
    "text": "query utils_publicGetRecordingLinkQuery(\n  $accessKey: ID!\n  $recordingId: ID\n) {\n  node(id: $accessKey) {\n    __typename\n    ... on Note {\n      id\n      meetingRecordingLink {\n        url(forCopy: true, recordingId: $recordingId)\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c256a3cf17acfc7e0d1ba86a3b8e8545";

export default node;
