import React from "react";

import { Dimmer, Loader, SemanticSIZES } from "semantic-ui-react";

interface Props {
	/** Only show the spinner after this many milliseconds */
	delay?: number;
	/** If true, renders inline instead of absolutely centring; if "centered", renders inline, but center-aligned  */
	inline?: boolean | "centered";
	/** Render the loader inside a Semantic dimmer element, which will cover the nearest positioned ancestor */
	dimmer?: boolean;
	size?: SemanticSIZES;
	className?: string;
}

export default function LoadingSpinner({ delay, inline, size, dimmer = true, className }: Props) {
	const [show, setShow] = React.useState(!delay);

	React.useEffect(() => {
		if (!delay) return;
		const timeout = window.setTimeout(() => setShow(true), delay);
		return () => window.clearTimeout(timeout);
	}, [delay, setShow]);

	if (!show) return null;

	const loaderNode = <Loader active inline={inline} size={size} className={className} />;
	if (dimmer)
		return (
			<Dimmer active inverted>
				{loaderNode}
			</Dimmer>
		);
	return loaderNode;
}
