import * as Sentry from "@sentry/browser";

import { AuthPostMessage, setWindowProperties, signOutMobile } from "~/lib/auth/index";
import { AuthFlowType } from "~/types/main.d";

let importPromise: Promise<object[]>;
export function ensureImported() {
	if (!importPromise) {
		importPromise = Promise.all([
			import("@codetrix-studio/capacitor-google-auth"),
			import("@byteowls/capacitor-oauth2"),
		]);
	}
	return importPromise;
}

export function parseJSONIfOk(response: Response) {
	if (response.ok) {
		return response.json();
	} else {
		Sentry.withScope(async function (scope) {
			try {
				scope.setExtras({ json: await response.json() });
			} catch {
				try {
					scope.setExtras({ text: await response.text() });
				} catch {}
			}
			Sentry.captureMessage("Auth parse failure");
		});
		throw new Error(`Login failure: unable to contact the server.`);
	}
}

/** The JSON response from the MobileTokenUpload view */
export interface MobileTokenUploadResponse {
	success: boolean;
	message: string;
	code: string | undefined | null;
	user: FellowUser;
	account: FellowAccount;
	session_key: string; // eslint-disable-line camelcase
	next?: string;
	signup?: AuthFlowType;
	app_config?: FellowAppConfig; // eslint-disable-line camelcase
	new_csrf_token?: string; // eslint-disable-line camelcase
}

export function resolveToPostMessage(json: MobileTokenUploadResponse): AuthPostMessage {
	if (json.new_csrf_token) {
		window.CSRF_TOKEN = json.new_csrf_token;
	}

	if (!json.success) {
		Sentry.withScope(function (scope) {
			// Sentry has this argument typed as `Record<string, unknown>`, which won't accept this without the splat
			scope.setExtras({ ...json });
			Sentry.captureMessage("Mobile auth failure");
		});
		signOutMobile();
		throw {
			channel: "identity.auth",
			result: "error",
			message: json.message,
			code: json.code || null,
			errorType: "AuthenticationError",
		};
	} else {
		const data = {
			channel: "identity.auth",
			message: "",
			code: null,
			result: "ok",
			account: json.account,
			user: json.user,
			signup: json.signup,
			next: json.next,
			// eslint-disable-next-line camelcase
			app_config: json.app_config,
		};

		setWindowProperties(data);

		return data;
	}
}
