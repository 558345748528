type IsStrictSubType<A, B> = A extends B ? (B extends A ? false : true) : false;
type ObjectKey = string | number | symbol;

/**
 * Check if `obj` has `prop` as an "own" property. If it does, the type of
 * `obj` will be narrowed to include that property.
 */
export function hasOwnProperty<P extends string>(
	obj: Readonly<{}>,
	prop: IsStrictSubType<P, ObjectKey> extends true ? P : never,
): obj is { [k in P]: unknown };
/**
 * Check if `obj` has the given `prop` as an "own" property. The type of `obj`
 * is _not_ narrowed, since the type of `prop` is not specific enough to do so.
 */
export function hasOwnProperty<T extends {}>(obj: Readonly<T>, prop: ObjectKey): prop is keyof T;

// The implementation for the above overloads.
export function hasOwnProperty(obj: Readonly<{}>, prop: ObjectKey): boolean {
	return Object.prototype.hasOwnProperty.call(obj, prop);
}

// Tests (left here to make sure the type guard works how we want)
if (process.env.NODE_ENV !== "production") {
	type IsSameType<A, B> = A extends B ? (B extends A ? true : false) : false;

	function expectType<Expected, Actual>(_arg: IsSameType<Expected, Actual> extends true ? Actual : never): void {}

	const n = { test: 123 };
	if (hasOwnProperty(n, "abc")) {
		expectType<{ test: number; abc: unknown }, typeof n>(n);
	}

	const o: {} = "whatever";

	if (hasOwnProperty(o, "test")) {
		expectType<{ test: unknown }, typeof o>(o);
	}

	// eslint-disable-next-line @typescript-eslint/no-inferrable-types
	const p: string = "any property";

	if (hasOwnProperty(o, p)) {
		expectType<{}, typeof o>(o);
	}

	// eslint-disable-next-line @typescript-eslint/no-inferrable-types
	const key: string = "";

	const constObj = { x: 123 } as const;
	if (hasOwnProperty(constObj, key)) {
		expectType<"x", typeof key>(key);
		const result = constObj[key];
		expectType<123, typeof result>(result);
	}
}
