import { userWillBePromptedToUpdateApp } from "~/lib/ensureAppUpdated";
import { confirm } from "~/lib/prompts";
import { makeServiceWorkerCall } from "~/lib/utils";
import FellowVersion, { versionLt, versionParse } from "~/lib/version";
import toast from "~/ui/toast";

export async function updateFellow(serverVersion: FellowVersion) {
	try {
		await makeServiceWorkerCall("fellowUpdated", { serverVersion: serverVersion });
	} catch (error) {
		if (error instanceof Error && error.message !== "Service worker not available.") {
			console.error(error);
		}
	}

	window.location.reload();
}

let promptOpen = false;

export const validateVersion = async (serverVersionString: string, forwardOnly: boolean) => {
	const serverVersion = new FellowVersion(serverVersionString);
	const clientVersion = new FellowVersion(window.APP_VERSION);

	if (
		serverVersion.primaryString &&
		serverVersion.primaryString !== clientVersion.primaryString &&
		!(await userWillBePromptedToUpdateApp()) &&
		!promptOpen
	) {
		if (
			forwardOnly &&
			versionLt(versionParse(serverVersion.primaryString), versionParse(clientVersion.primaryString))
		) {
			return true;
		}
		promptOpen = true;
		confirm(
			"Fellow has been updated and needs to refresh. " +
				"If you have unsaved changes, you can continue working and refresh at a later time.",
			{
				title: "Update Required",
				yesText: "Refresh",
				noText: "Later",
			},
		).then(result => {
			if (result) {
				updateFellow(serverVersion);
			} else {
				toast.warn("Click here to refresh Fellow", {
					closeButton: false,
					autoClose: false,
					onClose: updateFellow.bind(null, serverVersion),
				});
			}
		});
		return false;
	}

	return true;
};
