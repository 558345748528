import React from "react";
import { Link, LinkProps } from "react-router-dom";

import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { compact } from "lodash";

import { Button } from "~/ui";

import styles from "./css/IconButton.module.less";

interface BaseProps {
	icon: IconDefinition;
	transform?: string;
	memoizeIcon?: boolean;
	flip?: React.ComponentProps<typeof FontAwesomeIcon>["flip"];
}

type ButtonProps = Omit<React.ComponentPropsWithoutRef<typeof Button>, keyof BaseProps | "slim">;

export type IconButtonProps = Omit<ButtonProps, "variant"> &
	BaseProps & { variant?: ButtonProps["variant"]; iconSize?: SizeProp };

const MemoizedFontAwesomeIcon = React.memo(FontAwesomeIcon);

const DEFAULT_TRANSFORM: { [k in IconName]?: string } = {
	"ellipsis-v": "scale(1.5)",
	"chevron-left": "translateX(-15%)",
	"chevron-right": "translateX(15%)",
	"angle-left": "translateX(12%)",
	"angle-right": "translateX(-12%)",
};

export default React.forwardRef(function IconButton(
	{ icon, memoizeIcon, transform, className, variant = "tertiary", flip, iconSize, ...buttonProps }: IconButtonProps,
	ref: React.Ref<HTMLButtonElement>,
) {
	const transforms = compact([DEFAULT_TRANSFORM[icon.iconName], transform]).join(" ");
	const inlineStyles = React.useMemo(() => (transforms ? { transform: transforms } : {}), [transforms]);

	const Icon = memoizeIcon ? MemoizedFontAwesomeIcon : FontAwesomeIcon;

	return (
		<Button
			ref={ref}
			variant={variant}
			className={cx(styles.button, className, styles[`variant-${variant}`])}
			{...buttonProps}
			size="wide"
		>
			<Icon style={inlineStyles} icon={icon} flip={flip} size={iconSize} />
		</Button>
	);
});

type IconLinkProps = BaseProps &
	(
		| ({ external: true } & Omit<React.HTMLProps<HTMLAnchorElement>, keyof BaseProps>)
		| ({ external?: false } & Omit<LinkProps, keyof BaseProps>)
	);

export function IconLink({ icon, transform, className, ...rest }: IconLinkProps) {
	const transforms = compact([DEFAULT_TRANSFORM[icon.iconName], transform]).join(" ");
	const inlineStyles = transforms ? { transform: transforms } : {};
	const classes = cx(styles.button, className);
	const content = <FontAwesomeIcon style={inlineStyles} icon={icon} />;

	if (rest.external) {
		const { external: _external, ...props } = rest;
		return (
			<a className={classes} {...props}>
				{content}
			</a>
		);
	} else {
		const { external: _external, ...props } = rest;
		return (
			<Link className={classes} {...props}>
				{content}
			</Link>
		);
	}
}
