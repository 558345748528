// Base-64 encoding and decoding functions that understand Python's urlsafe
// encoding and decoding functions.
//
// Basically this just means replacing `+` with `-` and `/` with `_` if it
// appears in the encoded string.
//
// See: https://docs.python.org/3/library/base64.html#base64.urlsafe_b64encode

export function urlsafeB64Encode(raw: string): string {
	return base64Encode(raw).replace(/\+/g, "-").replace(/\//g, "_");
}

export function urlsafeB64Decode(encoded: string): string {
	return base64Decode(encoded.replace(/-/g, "+").replace(/_/g, "/"));
}

export function base64Encode(str: string) {
	// We need unicode support in b64 strings
	// first we use encodeURIComponent to get percent-encoded UTF-8,
	// then we convert the percent encodings into raw bytes which
	// can be fed into btoa.
	// See: https://stackoverflow.com/a/30106551/1612471
	return btoa(
		encodeURIComponent(str).replace(/%([\dA-F]{2})/g, (_match, p1) => {
			return String.fromCharCode(parseInt(p1, 16));
		}),
	);
}

export function base64Decode(str: string) {
	// Going backwards: from bytestream, to percent-encoding, to original string.
	return decodeURIComponent(
		atob(str)
			.split("")
			.map(function (c) {
				return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
			})
			.join(""),
	);
}
